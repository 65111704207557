import React, { useState, useEffect } from 'react';
import { Col, Row, Slider, Card, Image, Spin } from 'antd';



// imageLinks: a list containing the links to the images to display
// nPerRow: the number of images to display in a row

const ImageSelector = ({imageLinks,radioMode, nPerRow=2, callback, selected=[]}) => {

  const [selectedImages, setSelectedImages] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null)
    useEffect(() => {
      if(imageLinks == null){
        setSelectedImages(null)
      }
      else{
        const selections = []
        for(var j = 0; j < imageLinks.length; j++){
          selections.push(selected.includes(j))
        }
        
        setSelectedImages(selections)
      }

    }, [imageLinks]);

  const updateSelection = (index) =>{
    var newArray = selectedImages.slice();
    if(radioMode){
      if(selectedIndex != null){
        newArray[selectedIndex] = false
      }
      newArray[index] = true
      setSelectedIndex(index)
    } else{
      newArray[index] =  !selectedImages[index]
    }
    setSelectedImages(newArray)
    if(callback){
      callback(newArray.map((value, index) => value ? index : -1).filter(index => index !== -1)) // filter out the -1 indices)
    }
  }


    // const currentSuggestions = spriteSuggestions[i]
    // var index = 0;
    const imageRows = []
    let imageCols = []

    const colSpan = 24 / nPerRow;
    let n = imageLinks.length
    // console.log(colSpan)
    for(let j = 0; j < n; j++){
      if(selectedImages == null){
        break
      }
      const color = selectedImages[j] ? "green" : ""
      imageCols.push(
        <Col span={colSpan} style={{background:color, padding:3, textAlign:"center"}}>
          <Image width="80%" src={imageLinks[j]} preview={false} onClick={()=>(updateSelection(j))}/>
        </Col>
        )
      if(j+1 % nPerRow == 0 || j == n -1){
          imageRows.push(
            <Row>
              {imageCols}
            </Row>)
          imageCols = []
      }


    }


  return(
    <>
      {imageRows}
    </>
  )

}

export default ImageSelector;