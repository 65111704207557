// import React from 'react';
// import { Routes, Route } from 'react-router-dom';

// import ModuleList from "./ModuleList";
// import ThemeStormer from "./ThemeStormer";

// const Main = () => {
//   return (
//     <Routes>
//       <Route exact path='/' element={<ModuleList/>}></Route>
//       <Route exact path='/themestormer' element={<ThemeStormer/>}></Route>
//     </Routes>
//   );
// }

// export default Main;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { server, ProvideAuth, RequireAuth, useAuth } from "../useAuth"
import ModuleList from "./ModuleList";
import ThemeStormer from "./ThemeStormer";
import ProjectStormer from "./ProjectStormer";
import PicStormer from "./PicStormer";
import {Login} from './Login'

const Main = () => {
  return (
    <ProvideAuth>
    <Routes>
      <Route exact path='/login' element={<Login/>}/>
      <Route exact path='/' element={<RequireAuth redirectTo='login'><ModuleList/></RequireAuth>} />
      <Route exact path='/themestormer' element={<RequireAuth redirectTo='login'><ThemeStormer /></RequireAuth>} />
      <Route exact path='/projectstormer' element={<RequireAuth redirectTo='login'><ProjectStormer /></RequireAuth>} />
      <Route exact path='/picstormer' element={<RequireAuth redirectTo='login'><PicStormer /></RequireAuth>} />
    </Routes>
    </ProvideAuth>
  );
}

export default Main;