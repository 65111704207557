import React, { useState, useEffect } from 'react';
import { Typography, Row,Col, Column, Card, Button, Spin, Input } from 'antd';
import aa from '../assets/images/PicStormerIcons/Brown.svg'
import tan from '../assets/images/PicStormerIcons/Tan.svg'
import light from '../assets/images/PicStormerIcons/LightBrown.svg'
import pale from '../assets/images/PicStormerIcons/Caucasian.svg'
import black from '../assets/images/PicStormerIcons/Black.svg'
import blonde from '../assets/images/PicStormerIcons/Blonde.svg'
import red from '../assets/images/PicStormerIcons/Red.svg'
import baby from '../assets/images/PicStormerIcons/Baby.svg'
import child from '../assets/images/PicStormerIcons/Child.svg'
import adult from '../assets/images/PicStormerIcons/Adult.svg'
import old from '../assets/images/PicStormerIcons/Old.svg'
import blue from '../assets/images/PicStormerIcons/Blue.svg'
import green from '../assets/images/PicStormerIcons/Green.svg'
import ImageSelector from './ImageSelector';
import {generateImage, logData} from '../api.js'
import { useAuth } from '../useAuth'
const { Title } = Typography;
const { TextArea } = Input;


// spriteNames: a list containing the names of the sprites in the project, ie: ['Hula Dancer', 'Pineaple Float', 'Scratch Cat']
// spriteSuggestions: a 2D list containing the suggested sprites from the Scratch asset library,
//  the first nested list contains the suggestions for the first sprite in spriteNames (ie suggestions for Hula Dancer),
//  tool will hang until spriteSugesstions is provided (ie until server responds with suggestions)
// setSprites callback function for when user makes all sprite selections
const GenerationOverview = ({spriteName, spriteType, nPerRow=3, callback=()=>{console.log('ERROR No callback on generate')}}) => {

  const rc = useAuth().getResearchCode();
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [generating, setGenerating] = useState(false);
  const [generatedResults, setGeneratedResults] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [spriteDescription, setSpriteDescription] = useState('');

  const MAX_GENERATIONS = 7

  useEffect(() => {
    setSelectedAttributes({});
    setGenerating(false);
    setGeneratedResults([]);
    setSelectedImages([]);
    setSpriteDescription('');
  }, [spriteName]);


  // TODO: David replace the attributes to links containing the actual pictures
  const cat = "https://en.scratch-wiki.info/w/images/ScratchCat3.0.svg"

  const humanAttributes={
    'Skin Color':[['african american',aa],['dark',tan],['tan',light],['pale', pale]],
    'Hair Color':[['black',black],['brown',aa],['blonde',blonde],['red', red]],
    'Age':[['baby',baby],['child',child],['adult', adult],['old', old]],
    'Eye Color':[['brown',aa],['hazel',light],['blue',blue],['green', green]]
  }


  const updateSelections = (attribute, index) =>{
    // console.log(`${attribute}:${humanAttributes[attribute][index][0]}`)
    const newAttributes = structuredClone(selectedAttributes)
    newAttributes[attribute] = index
    // console.log(newAttributes)
    setSelectedAttributes(newAttributes)
  }

  const generateImages = ()=>{
    setGenerating(true)
    const tempAttributes = {}
    for(const attr in selectedAttributes){
      tempAttributes[attr] = humanAttributes[attr][selectedAttributes[attr]][0];
    }
    // console.log(tempAttributes);
    const attrs = Object.entries(tempAttributes)
        .map(([key, value]) => `${key}:${value}`)
        .join('\n');
    const prompt= spriteType.toLowerCase().includes('human') ? `Generate a A simple 2-d drawing of ${spriteName}. The picture should not be cropped (showing face, arms, and feet), on a white background. The sprite has the following attributes:\n${attrs}`:
      (spriteType == 'backdrop' ? `Make a 2D background image of ${spriteName} consider the following suggestions: ${spriteDescription}`: `Make a picture of ${spriteName} following this description: ${spriteDescription}. NOTE: The picture should have a simple 2D style and it is crucial that it has no background just white.`)
    // console.log(prompt)
      logData(rc,'PICSTORMER_GEN_REQUEST', {prompt:prompt})
    generateImage(prompt, 4, (r)=>{setGeneratedResults([...generatedResults, ...r.data.images]); setGenerating(false);logData(rc,'PICSTORMER_IMAGES_GENERATED',{images:r.data.images})});
    // setGeneratedResults([...generatedResults, cat,cat,cat])
    // setGenerating(false)
  }


  // Build the form to collect all the specified attributes =====================
  const order=['Skin Color', 'Hair Color', 'Age', 'Eye Color']
  // console.log(humanAttributes)
  const rows = []
  let cols = []
  const colSpan = 24 / nPerRow;

  for(let i = 0; i < order.length; i++){
    const currentAttribute = order[i]
    const imageLinks  = humanAttributes[currentAttribute].map(subArray => subArray[1]);
    cols.push(
        <Col span={colSpan}>
          <Card title={currentAttribute}>
            <ImageSelector imageLinks={imageLinks} nPerRow={4} radioMode={true} callback={(i)=>updateSelections(currentAttribute, i[0])} 
              selected={selectedAttributes[currentAttribute] != null ? [selectedAttributes[currentAttribute]] : []}/>

          </Card>
        </Col>
    )
    if(i+1 % nPerRow == 0 || i == order.length-1){
      rows.push(
        <Row>
          {cols}
        </Row>
      )
    }
  }

  const humanForm = <>
                      {rows}
                    </>
// ================================================================================

  const objectForm = <>
                      <TextArea rows={4} 
                      placeholder={`Describe ${spriteName}`}
                      value={spriteDescription}
                      onChange={(e)=>{setSpriteDescription(e.target.value);}} />
                    </>

  const generatedSelector = 
    <Card title={"Generated Images"}>
      <Spin spinning={generatedResults.length == 0 || generating}>
        <ImageSelector imageLinks={generatedResults} nPerRow={3} selected={selectedImages} callback={setSelectedImages}/>
        <Row>
                <Col span={12}>
                  {generatedResults.length < MAX_GENERATIONS ?
                    <Button type="primary" onClick={generateImages}>
                      Generate More Images
                    </Button> :
                    <Button type="primary" disabled>
                      Generate More Images
                    </Button>
                    }
                </Col>
                <Col span={12}>
                  {generating || selectedImages.length > 0 ? 
                    <Button type="primary" span={12} onClick={()=>callback(selectedImages.map((i)=>generatedResults[i]))}>
                                      Finish
                    </Button> : 
                    <Button type="primary" span={12} disabled>
                                      Finish
                    </Button> }
                </Col>
              </Row>
      </Spin>
    </Card>



  return(
    <div style={{textAlign:'center'}}>
      <Title level={3}>{spriteName}</Title>
      {generating || generatedResults.length > 0 ? generatedSelector : (spriteType.toLowerCase().includes('human') ? humanForm : objectForm)}
      {generating || generatedResults.length > 0 ? [] : <Button type="primary" onClick={generateImages}>
                        Generate Images
            </Button>}
    </div>
  )

}

export default GenerationOverview;