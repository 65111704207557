import React, { useState } from 'react';

function CustomIdeaForm({ onSubmit }) {
  const [projectName, setProjectName] = useState('');
  const [backdrop, setBackdrop] = useState('');
  const [sprites, setSprites] = useState([
    { name: '', type: 'object', functionality: '' },
    { name: '', type: 'object', functionality: '' },
    { name: '', type: 'object', functionality: '' },
  ]);

  const handleSpriteChange = (index, field, value) => {
    const newSprites = [...sprites];
    newSprites[index][field] = value;
    setSprites(newSprites);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const customIdea = `
#### Project name: ${projectName}
**Backdrop:** ${backdrop}

**Three sprites:**
- Sprite 1: ${sprites[0].name} (${sprites[0].type})
- Sprite 2: ${sprites[1].name} (${sprites[1].type})
- Sprite 3: ${sprites[2].name} (${sprites[2].type})

**Sprite functionality:**
- ${sprites[0].name}: ${sprites[0].functionality}
- ${sprites[1].name}: ${sprites[1].functionality}
- ${sprites[2].name}: ${sprites[2].functionality}
    `;
    onSubmit(customIdea);
  };

  return (
    <div className="container mt-4">
      <h3 className="text-center">Or Enter My Own Project Idea:</h3>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Project name:</label>
          <input
            type="text"
            className="form-control"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Backdrop:</label>
          <input
            type="text"
            className="form-control"
            value={backdrop}
            onChange={(e) => setBackdrop(e.target.value)}
            required
          />
        </div>
        {sprites.map((sprite, index) => (
          <div key={index} className="mb-3">
            <label className="form-label">Sprite {index + 1}:</label>

            <div className="row mb-3">
              <div className="col-lg-8">
              <input
                type="text"
                className="form-control"
                value={sprite.name}
                onChange={(e) => handleSpriteChange(index, 'name', e.target.value)}
                required
              />
              </div>
              <div className="form-check col-auto">
                <input
                  className="form-check-input"
                  type="radio"
                  name={`sprite${index}Type`}
                  id={`sprite${index}TypeHuman`}
                  value="human"
                  checked={sprite.type === 'human'}
                  onChange={(e) => handleSpriteChange(index, 'type', e.target.value)}
                />
                <label className="form-check-label" htmlFor={`sprite${index}TypeHuman`}>
                  Human
                </label>
              </div>
              <div className="form-check col-auto">
                <input
                  className="form-check-input"
                  type="radio"
                  name={`sprite${index}Type`}
                  id={`sprite${index}TypeAnimal`}
                  value="animal"
                  checked={sprite.type === 'animal'}
                  onChange={(e) => handleSpriteChange(index, 'type', e.target.value)}
                />
                <label className="form-check-label" htmlFor={`sprite${index}TypeAnimal`}>
                  Animal
                </label>
              </div>
              <div className="form-check col-auto">
                <input
                  className="form-check-input"
                  type="radio"
                  name={`sprite${index}Type`}
                  id={`sprite${index}TypeObject`}
                  value="object"
                  checked={sprite.type === 'object'}
                  onChange={(e) => handleSpriteChange(index, 'type', e.target.value)}
                />
                <label className="form-check-label" htmlFor={`sprite${index}TypeObject`}>
                  Object
                </label>
              </div>
            </div>


            <label className="form-label mt-2">Sprite {index + 1} functionality:</label>
            <input
              type="text"
              className="form-control"
              value={sprite.functionality}
              placeholder="Note that sprite 1 must stays in place, sprite 2 and 3 must move across the screen"
              onChange={(e) => handleSpriteChange(index, 'functionality', e.target.value)}
              required
            />
          </div>
        ))}
        <div className="text-center">
          <button type="submit" className="btn btn-primary">Use my own idea</button>
        </div>
      </form>
    </div>
  );
}

export default CustomIdeaForm;