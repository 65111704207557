import React, { useState, useEffect, useContext, createContext } from "react";
import { Navigate } from "react-router-dom";
import axios from 'axios'
import jwt from "jwt-decode";
//Adapted from https://usehooks.com/useAuth/

const authContext = createContext();
export const server = 'https://backend-quantime.link/';
// export const server = 'http://127.0.0.1:8080/';

// export const server = 'http://quantimebackend-env.eba-jeqe4vzg.us-east-2.elasticbeanstalk.com/'
// Provider component that wraps your app and makes auth object
// available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
	const auth =  useProvideAuth();
	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object
// and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

export function RequireAuth({ children, redirectTo }) {
  let isAuthenticated = useAuth();
  // let curr_token = localStorage.getItem('jwt-token')
  // console.log(curr_token)
  function handleResponse(r){
  	return children
  }

  if(isAuthenticated && isAuthenticated.token && isAuthenticated.token.data){
	  return children
  }
  return <Navigate to={redirectTo} />;
}

function useProvideAuth() {
	// user hook
	const [token, setToken] = useState(
		() => JSON.parse(localStorage.getItem('jwt-token')));

	const signin = (user, dest, fail=(e)=> {console.log("oopsie")}) => {
		const credentials = {reserachCode:user};
		// if(user === "GUEST"){
		// 	setToken({data:"GUEST"})
		// 	localStorage.setItem('jwt-token', null)
		// 	dest()
		// }
		function handleResponse(token){
			if(token && token.data !== ''){

				setToken(token)
				localStorage.setItem('jwt-token', JSON.stringify(token))
				dest()
			}
		}
		// return axios.post(server+'login', credentials).then((token) => {token.data == '' ? setToken(token); localStorage.setItem('jwt-token',JSON.stringify(token)); dest() : console.log('Wrong Pass')}, (e)=> {console.log(e)})
		return axios.post(server+'login', credentials).then(handleResponse, (e)=>fail(e))
	}

	const signout = () => {
		setToken(null);
		localStorage.setItem('jwt-token', null)
		return <Navigate to='/' />;
	}

	const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
	}



	const getResearchCode = ()=>{
		// print(token.data)
		return parseJwt(token.data).user
	}
	  return {
    token,
    signin,
    parseJwt,
    getResearchCode
  };
}