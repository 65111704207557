// import React, { useState, useEffect } from 'react';

import Main from "./components/Main";

function App() {
  // const [currentTime, setCurrentTime] = useState(0);

  // useEffect(() => {
  //   fetch('/time').then(res => res.json()).then(data => {
  //     setCurrentTime(data.time);
  //   });
  // }, []);

  return (
    <div className="container-fluid">
      <Main />
    </div>
  );
}

export default App;
