import { Link } from 'react-router-dom';

function NavBarTheme() {
  return (
    <div className="col-auto col-md-3 col-xl-2 px-sm-1 px-0 bg-light">
      <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
            <li className="nav-item">
                <Link to="/" className="nav-link align-middle px-0">
                  <i className="bi bi-check-circle-fill text-success"></i><span className="ms-1 d-none d-sm-inline fw-bolder text-success">Module</span>
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/themestormer" className="nav-link align-middle px-0">
                  <i className="bi bi-pencil-fill"></i><span className="ms-1 d-none d-sm-inline fw-bolder text-decoration-underline">Theme</span>
                </Link>
            </li>
            <li className="nav-item">
                <Link to="#" className="nav-link align-middle px-0">
                  <i className="bi bi-three-dots"></i><span className="ms-1 d-none d-sm-inline">Project Idea</span>
                </Link>
            </li>
            <li className="nav-item">
                <Link to="#" className="nav-link align-middle px-0">
                  <i className="bi bi-three-dots"></i><span className="ms-1 d-none d-sm-inline">Sprites & Backdrop</span>
                </Link>
            </li>
            {/*<li className="nav-item">
                <Link to="#" className="nav-link align-middle px-0">
                  <i className="bi bi-three-dots"></i><span className="ms-1 d-none d-sm-inline">Project Creation</span>
                </Link>
            </li>*/}
        </ul>
      </div>
    </div>
  );
}

export default NavBarTheme;