import React, { useState, useEffect } from 'react';
import { Col, Row, Slider, Card, Image, Spin,Button } from 'antd';
import ImageSelector from './ImageSelector';


// spriteNames: a list containing the names of the sprites in the project, ie: ['Hula Dancer', 'Pineaple Float', 'Scratch Cat']
// spriteSuggestions: a 2D list containing the suggested sprites from the Scratch asset library,
//  the first nested list contains the suggestions for the first sprite in spriteNames (ie suggestions for Hula Dancer),
//  tool will hang until spriteSugesstions is provided (ie until server responds with suggestions)
// setSprites callback function for when user makes all sprite selections
const SuggestionsOverview = ({spriteNames, spriteSuggestions, setSprites, onGenerate, selected={}, updateSelections=(n, s)=>console.log('ERROR Suggestions has no callback')}) => {

  const nSprites = spriteNames.length
  const spanSize = 24 / nSprites
  const gutter = 16

  const cols = []

  // const updateSelections()

  for(let i = 0; i < nSprites; i++){
    const content = []
    const name = spriteNames[i]
    const selections = selected[name] == null ? [] :  selected[name]
    cols.push(<Col span={spanSize}>
                <Card title={name} style={{textAlign:"center"}}>
                  <Spin spinning={spriteSuggestions==null}>
                    <ImageSelector imageLinks={spriteSuggestions[i]} selected={selections} nPerRow={2} callback={(selections) => updateSelections(name, selections)}/>

                <Button type="primary" onClick={()=>onGenerate(spriteNames[i])}>
                  Generate New Images
                </Button>
                  </Spin>
                </Card>
              </Col>)
  }


  return(
    <Row gutter={[gutter,gutter]}>
    {cols}
    </Row>
  )

}

export default SuggestionsOverview;